exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-bill-jsx": () => import("./../../../src/pages/bill.jsx" /* webpackChunkName: "component---src-pages-bill-jsx" */),
  "component---src-pages-garden-index-jsx": () => import("./../../../src/pages/garden/index.jsx" /* webpackChunkName: "component---src-pages-garden-index-jsx" */),
  "component---src-pages-gifts-index-jsx": () => import("./../../../src/pages/gifts/index.jsx" /* webpackChunkName: "component---src-pages-gifts-index-jsx" */),
  "component---src-pages-green-future-index-jsx": () => import("./../../../src/pages/green-future/index.jsx" /* webpackChunkName: "component---src-pages-green-future-index-jsx" */),
  "component---src-pages-guesthouse-book-a-room-jsx": () => import("./../../../src/pages/guesthouse/book-a-room.jsx" /* webpackChunkName: "component---src-pages-guesthouse-book-a-room-jsx" */),
  "component---src-pages-guesthouse-index-jsx": () => import("./../../../src/pages/guesthouse/index.jsx" /* webpackChunkName: "component---src-pages-guesthouse-index-jsx" */),
  "component---src-pages-guesthouse-planner-jsx": () => import("./../../../src/pages/guesthouse/planner.jsx" /* webpackChunkName: "component---src-pages-guesthouse-planner-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-info-index-jsx": () => import("./../../../src/pages/info/index.jsx" /* webpackChunkName: "component---src-pages-info-index-jsx" */),
  "component---src-pages-qr-code-jsx": () => import("./../../../src/pages/qr-code.jsx" /* webpackChunkName: "component---src-pages-qr-code-jsx" */),
  "component---src-templates-commons-page-index-jsx": () => import("./../../../src/templates/commons-page/index.jsx" /* webpackChunkName: "component---src-templates-commons-page-index-jsx" */),
  "component---src-templates-info-page-index-jsx": () => import("./../../../src/templates/info-page/index.jsx" /* webpackChunkName: "component---src-templates-info-page-index-jsx" */),
  "component---src-templates-space-page-debug-jsx": () => import("./../../../src/templates/space-page/debug.jsx" /* webpackChunkName: "component---src-templates-space-page-debug-jsx" */),
  "component---src-templates-space-page-index-jsx": () => import("./../../../src/templates/space-page/index.jsx" /* webpackChunkName: "component---src-templates-space-page-index-jsx" */)
}

